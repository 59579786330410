PictureFolder.prototype = new Folder();

function PictureFolder() {
    Folder.apply(this, arguments);
    this.type = "pictures";
	this.selected = false;
	this.selectedMode = false;
}



PictureFolder.prototype.constructor = PictureFolder;

PictureFolder.prototype.displayFolder = function(response) {
        $("#folder_content").html(`<div class="folder_path" id="picture_folder_path"></div>`);

        this.displayPath(response);
        
        if (!response.isRoot) {
            // Display parent
            $("#folder_content").append(this.makeParentDiv(response.parent));
        }
        // Display Subfolders
        var subdirs = response.directories;
        for (var i = 0; i < subdirs.length; ++i) {
            var dir = subdirs[i];
            $("#folder_content").append(this.makeFolderDiv(dir));
        }
        var pics = response.files;
        for (var i = 0; i < pics.length; ++i) {
            var pic = pics[i];
            $("#folder_content").append(this.makePictureDiv(pic));
        }
        this.initDragAndDrop();
    };

PictureFolder.prototype.movePicture = function(id, dest, callback) {
        $.ajax({
            url: "admin/pictures/api/move",
            method: "POST",
            data: {
                "destination": dest,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });

    };

PictureFolder.prototype.copyPicture = function(id, dest, callback) {
        $.ajax({
            url: "admin/pictures/api/copy",
            method: "POST",
            data: {
                "destination": dest,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.renamePicture = function(id, newname, callback) {
        $.ajax({
            url: "admin/pictures/api/rename",
            method: "POST",
            data: {
                "name": newname,
                "id": id
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.deletePicture = function(id, callback) {
        $.ajax({
            url: "admin/pictures/api/delete",
            method: "POST",
            data: {
                "id": id
            },
            success: function success(response) {
                callback(true);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.multiDelete = function(elements, callback) {
        $.ajax({
            url: "admin/pictures/api/multidelete",
            method: "POST",
            data: {
                "elements": elements
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.multiMove = function(elements, destination, callback) {
        $.ajax({
            url: "admin/pictures/api/multimove",
            method: "POST",
            data: {
                "elements": elements,
                "destination": destination
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.multiCopy = function(elements, destination, callback) {
        $.ajax({
            url: "admin/pictures/api/multicopy",
            method: "POST",
            data: {
                "elements": elements,
                "destination": destination
            },
            success: function success(response) {
                callback(response);
            },
            error: function error() {
                callback(false);
            }
        });
    };

PictureFolder.prototype.multiSelect = function() {
        this.selectMultiples = true;
        $("#admin_pictures_select_multiple").hide();
        $("#admin_pictures_select_single").show();
        // $("#admin_pictures_actions").show();
    };

PictureFolder.prototype.singleSelect = function() {
        this.selectMultiples = false;
        $("#admin_pictures_select_multiple").show();
        $("#admin_pictures_select_single").hide();
        // $("#admin_pictures_actions").hide();
        // $(".picture_selected").removeClass("picture_selected");
    }

PictureFolder.prototype.makeFolderDiv = function(folder) {
        return `
            <div class="picture_folder picture_element" id="folder_${folder.id}" type="folder" folder="${folder.id}" title="${folder.name}">
                <div class="picture_draggable">
                    <div class="picture_draggable_cursor">
                        <i class="material-icons">drag_handle</i>
                    </div>
                </div>
                <div class="picture_content">
                    <i class="material-icons folder-icon blue-text text-darken-2">folder</i>
                    <div class="picture_folder_name" id="folder_name_${folder.id}">${folder.name}</div>
                </div>
            </div>
        `;
    };

PictureFolder.prototype.displayPath = function(resp) {
    var path = resp.path;
    
    span = `<span class="folder_path_delimiter">/</span>`;
    patharr = []
    while (path) {
        var div = `
            <div class="folder_path_part waves-effect" folder="${path.id}">
                ${path.name}
            </div>
        `;
        path = path.parent;
        patharr.splice(0, 0, div);
    }

    $("#picture_folder_path").html(patharr.join(span));
}
    
PictureFolder.prototype.makeParentDiv = function(id) {        
        return `
            <div class="picture_folder picture_parent" type="parent" folder="${id}">
                <div class="picture_content">
                    <i class="material-icons folder-icon amber-text text-darken-2">folder</i>
                    Übergeordnetes Verzeichnis
                </div>
            </div>
        `;
    };

PictureFolder.prototype.makePictureDiv = function(pic) {        
        return `
            <div class="picture_picture picture_element" id="picture_${pic.id}" type="picture" picture="${pic.id}" title="${pic.name}" t="${pic.thumbnail}" l="${pic.large}" m="${pic.medium}">
                <div class="picture_draggable">
                    <div class="picture_draggable_cursor">
                        <i class="material-icons">drag_handle</i>
                    </div>
                </div>
                <div class="picture_content">
                    <img src="${pic.thumbnail}" /><br />
                    <span id="picture_name_${pic.id}">${pic.name}</span>
                </div>
            </div>
        `;
    };

PictureFolder.prototype.animateOut = function (selector) {
        $(selector).fadeTo("fast", 0, function () {
            $(selector).animate({
                width: 0,
                height: 0
            }, "fast", function () {
                $(selector).remove();
            });
        });
    };

PictureFolder.prototype.getSelected = function() {
        var selected = {
            pictures: new Array(),
            folders: new Array()
        }
        $(".picture_selected").each(function(index) {
            var type = $(this).attr("type");
            if (type == "folder") {
                selected.folders.push($(this).attr("folder"));
            } else if (type == "picture") {
                selected.pictures.push($(this).attr("picture"));
            }
        });
        return selected;
    };

PictureFolder.prototype.initDragAndDrop = function() {
        var that = this;
        $(".picture_picture").draggable({
            containment: ".maincontent",
            stack: ".picture_element",
            handle: ".picture_draggable",
            revert: true
        });
        $(".picture_folder:not(.picture_parent)").draggable({
            containment: ".maincontent",
            stack: ".picture_element",
            handle: ".picture_draggable",
            revert: true
        });
        $(".picture_folder").droppable({
            accept: ".picture_element",
            hoverClass: "picture_folder_drop_hover",
            drop: that.dropHandler
        });
    };

PictureFolder.prototype.dropHandler = function(event, ui) {
        var dest = $(this).attr("folder");
        // Freeze Element
        ui.draggable.draggable("disable");
        ui.draggable.draggable("option", "revert", false);
        
        var src_type = ui.draggable.attr("type");
        // Move Multiple Elements
        var selected = pictureFolder.getSelected();
        if (!ui.draggable.hasClass("picture_selected")) {
            ui.draggable.addClass("picture_selected");
            if (src_type == "folder") {
                var src_id = ui.draggable.attr("folder");
                selected.folders.push(src_id);
            } else if (src_type == "picture") {
                var src_id = ui.draggable.attr("picture");
                selected.pictures.push(src_id);
            }
        }          
        pictureFolder.multiMove(selected, dest, function(result) {
            if (result.errorcount == 0) {
                pictureFolder.animateOut(".picture_selected");
            } else {
                var notify = new Notification();
                notify.warning(`Nicht alle Elemente konnten verschoben werden: 
                               ${result.errorcount} Fehler`);
                for (var i = 0; i < result.moved.pictures.length; ++i) {
                    id = result.moved.pictures[i];
                    pictureFolder.animateOut("#picture_"+id);
                }
                for (var i = 0; i < result.moved.folders.length; ++i) {
                    id = result.moved.folders[i];
                    pictureFolder.animateOut("#folder_"+id);
                }
            }
        });
    };

PictureFolder.prototype.paste = function() {	
	$("#admin_pictures_paste").hide();
	$("#admin_pictures_link").hide();
	var that = this;
	switch (this.selectedMode) {
		default:
			break;
		case "cut":			
			this.multiMove(this.selected, this.id, function(resp) {
				if (!resp) {
					new Notification().error("Verschieben fehlgeschlagen");
				} else {
					if (resp.errorcount == 0) {
						new Notification().success("Alle Elemente verschoben");
					} else {
						new Notification().warning(`${resp.errorcount} Elemente konnten nicht verschoben werden`);
					}
				}
				that.update();
			});
			break;
		case "copy":
			this.multiCopy(this.selected, this.id, function(resp) {
				if (!resp) {
					new Notification().error("Kopieren fehlgeschlagen");
				} else {
					if (resp.errorcount == 0) {
						new Notification().success("Alle Elemente kopiert");
					} else {
						new Notification().warning(`${resp.errorcount} Elemente konnten nicht kopiert werden`);
					}
				}
				that.update();
			});
			break;
	}
	this.selectedMode = false;
}

PictureFolder.prototype.link = function() {
	$("#admin_pictures_paste").hide();
	$("#admin_pictures_link").hide();	
}
	
PictureFolder.prototype.cut = function(selected, countstr) {
	this.selected = selected;
	this.selectedMode = "cut";
	new Notification().info(`${countstr} zum Verschieben markiert`);
	$("#admin_pictures_paste").show();
}

PictureFolder.prototype.copy = function(selected, countstr) {
	this.selected = selected;
	this.selectedMode = "copy";
	new Notification().info(`${countstr} zum Kopieren markiert`);
	$("#admin_pictures_paste").show();
	//$("#admin_pictures_link").show();
}

PictureFolder.prototype.blurSelected = async function(selected, countstr, pixels) {
    let count = selected.pictures.length;
    let dialog = new Dialog();
    let index = 0;
    dialog.loading(`${countstr} werden geblurred...`, `0 / ${count}...`);
    for (let picture of selected.pictures) {
        index++;
        dialog.getNode().find(".dialog_text").text(`${index} / ${count}...`);
        try {
            let file = await $.ajax({
                "url": "api/pictures/blur",
                "type": "post",
                "data": {
                    "picture": picture,
                    "range": pixels
                }
            });
        } catch (e) {
            new Notification().error("Bild konnte nicht geblurred werden...");
        }
    }
    new Notification().info("Vorgang abgeschlossen");
    pictureFolder.update();
    dialog.close();
}

PictureFolder.prototype.multidownload = function(selected, countstr) {
	this.selected = selected;
    if (selected.pictures.length > 50) {
        new Dialog().alert("Es können maximal 50 Elemente auf einmal runtergeladen werden");
        return;
    }
	new Notification().info(`${countstr} werden heruntergeladen.`);
    
    var w = window.open("api/pictures/zip?pictures=" + selected.pictures.join(",") + "&folders=" + selected.folders.join(","), "_blank");
    w.focus();
    
	//$("#admin_pictures_link").show();
}

PictureFolder.prototype.deleteSelected = function(selected, countstr) {
    var dlg = new Dialog();    
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie die ausgewählten Elemente wirklich unwiderruflich löschen?<br />
        Sie haben ${countstr} ausgewählt.`,
        "Ja",
        "Nein",
        function(result) {
            if (result) {
                dlg.block();
                pictureFolder.multiDelete(selected, function(result) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (!result) {
                        notify.error("Beim löschen ist ein Fehler aufgetreten");
                    } else {
                        dlg.close();
                        if (result.errorcount == 0) {
                            notify.success("Löschen erfolgreich");
                            pictureFolder.animateOut(".picture_selected");
                        } else {
                            notify.warning(`Nicht alle Elemente konnten gelöscht werden: 
                                           ${result.errorcount} Fehler`);
                            for (var i = 0; i < result.deleted.pictures.length; ++i) {
                                id = result.deleted.pictures[i];
                                pictureFolder.animateOut("#picture_"+id);
                            }
                            for (var i = 0; i < result.deleted.folders.length; ++i) {
                                id = result.deleted.folders[i];
                                pictureFolder.animateOut("#folder_"+id);
                            }
                        }
                    }
                });
            } else {
                dlg.close();
            }
        }
    );        
}

PictureFolder.prototype.addToGallery = function(selected, countstr) {
    var loading = new Dialog();
    loading.loading(
        "Bitte warten",
        "Galerien werden laden..."
    );

    var success = function(galleries) {
        loading.close();

        var gallery_strings = new Array();
        for (var i = 0; i < galleries.length; ++i) {
            gallery_strings.push(galleries[i].title);
        }

        var dlg = new Dialog();
        dlg.searchSelect(
            "Galerie wählen",
            `Wählen Sie die Galerie aus, zu der Sie ${countstr} hinzufügen möchten.
             Wählen Sie die Galerie aus der Liste aus oder suchen Sie danach.`,
            "Suchen",
            "Galeriename",
            gallery_strings,
            "Abbrechen",
            function(result) {
                if (result === false) {                    
                    dlg.close();
                } else {
                    dlg.block();
                    var gallery_id = galleries[result].id;
                    var galleryAdmin = new GalleryAdmin();
                    galleryAdmin.addElements(gallery_id, selected, function(gallery_result) {
                        dlg.unblock();
                        var notify = new Notification();
                        if (gallery_result === false) {
                            notify.error("Die Elemente konnten nicht hinzugefügt werden.");
                        } else {
                            dlg.close();
                            if (gallery_result.errorcount == 0) {
                                notify.success("Die Elemente wurden hinzugefügt.");
                            } else {
                                notify.warning(`Nicht alle Elemente wurden hinzugefügt: 
                                                ${gallery_result.errorcount} Fehler`);
                            }
                        }
                    })
                }
            }
        );
    }

    var error = function() {
        loading.close();
        var dlg = new Dialog();
        dlg.alert(
            "Fehler",
            "Beim Laden der Galerien ist ein Fehler aufgetreten",
            "Ok"
        );
    }

    $.ajax({
        url: "admin/galleries/api/list",
        method: "POST",
        success: function(response) {
            success(response.galleries);
        },
        error: function() {
            error();
        }
    });       
};

PictureFolder.prototype.addDeleteListener = function() {
    $(document).on("keydown.folder_content", function(e) {
        if (e.which == 46) {
            if (!$(e.target).is("body.mainbody") || Dialog.isOpened()) {
                return;
            }
            var selected = pictureFolder.getSelected();
            var count = selected.folders.length + selected.pictures.length;
            var countstr = count == 1 ? `ein Element` : `${count} Elemente`;
            if (count > 0) {
                pictureFolder.deleteSelected(selected, countstr);
            }
        }
    });
}


var pictureFolder = new PictureFolder();

$("body").on("click", "#admin_pictures_folder_create", function() {
    var dlg = new Dialog();
    dlg.input("Ordner erstellen",
        "Wählen Sie einen Namen für den Ordner",
        "Namen angeben:",
        "",
        "Neuer Ordner",
        "Ordner erstellen",
        "Abbrechen",
        function(res) {
            if (res === false) {
                dlg.close();
                return;
            }
            if (res.length == 0) {
                var notify = new Notification();
                notify.info("Der Titel darf nicht leer sein");
            } else {
                dlg.block();
                pictureFolder.createFolder(res, function(result) {
                    dlg.unblock();
                    var notify = new Notification();
                    if (result === false) {
                        notify.error("Ordner konnte nicht erstellt werden");
                    } else {
                        notify.success("Ordner wurde erstellt");
                        dlg.close();
                        pictureFolder.update();
                    }
                });
            }
        });
});

// IMAGE UPLOAD
$("body").on("click", "#admin_pictures_upload", function() {
    var dlg = new Dialog();
    dlg.dropzone(
        "Bilder hochladen",
        "Wählen Sie ein oder mehrere Bilder zum hochladen ein oder ziehen Sie sie in den Upload-Bereich",
        "Fertig",
        "admin/pictures/api/upload",
        Array("parent", pictureFolder.id),
        function(result) {
            dlg.close();
            var notify = new Notification();
            notify.info("Upload beendet");
            pictureFolder.update();
        }
    );
});

// Multi Select
$("body").on("click", "#admin_pictures_select_multiple", function() {
    pictureFolder.multiSelect();
});

// Single Select
$("body").on("click", "#admin_pictures_select_single", function() {
    pictureFolder.singleSelect();
});
$("body").on("click", "#admin_pictures_paste", function() {
    pictureFolder.paste();
});

$("body").on("keydown", function(event) {
	if (!$("#admin_pictures_actions").length) {
		return;
	}
	if (Dialog.isOpened()) {
		return;
	}
	var selected = pictureFolder.getSelected();
    var count = selected.folders.length + selected.pictures.length;	
    var countstr = count == 1 ? `ein Element` : `${count} Elemente`;
	
	if (event.ctrlKey || event.metaKey) {
		switch (String.fromCharCode(event.which).toLowerCase()) {
			case 'c':
				if (count > 0) {
					pictureFolder.copy(selected, countstr);
				}
				event.preventDefault();
				break;
			case 'x':
				if (count > 0) {
					pictureFolder.cut(selected, countstr);
				}
				event.preventDefault();
				break;
			case 'v':				
				pictureFolder.paste();
				event.preventDefault();
				break;
		}
	}
	
	if (event.keyCode == 46 && count > 0) {		
		pictureFolder.deleteSelected(selected, countstr);
	}
});

// Actions Select
$("body").on("click", "#admin_pictures_actions", function() {
    var selected = pictureFolder.getSelected();
    var count = selected.folders.length + selected.pictures.length;
    var actions = new Dialog();

    if (count == 0) {
        actions.alert("Keine Elemente", "Sie haben keine Elemente ausgewählt", "Ok");
        return;
    }
    var countstr = count == 1 ? `ein Element` : `${count} Elemente`;

    var cancel = function() { actions.close(); };

    actions.build()
        .addTitle("Ausgewählte Elemente")
        .addText(`Sie haben ${countstr} ausgewählt.<br />
                 Wählen Sie eine Aktion für die ausgewählten Elemente.`)
        .addLargeButton("Zu Galerie hinzufügen", "selected_to_gallery", function() {
            actions.close();
            pictureFolder.addToGallery(selected, countstr);
        })
        .addLargeButton("Zu Galerie hinzufügen", "selected_to_gallery", function() {
            actions.close();
            pictureFolder.addToGallery(selected, countstr);
        })
        .addLargeButton("Geblurrte Kopie (20px)", "selected_blur_20", function() {
            actions.close();
            pictureFolder.blurSelected(selected, countstr, 20);
        })
        .addLargeButton("Ausschneiden (Verschieben)", "selected_cut", function() {
            actions.close();
            pictureFolder.cut(selected, countstr);
        })
        .addLargeButton("Kopieren", "selected_copy", function() {
            actions.close();
            pictureFolder.copy(selected, countstr);
        })
        .addLargeButton("Herunterladen", "selected_download", function() {
            actions.close();
            pictureFolder.multidownload(selected, countstr);
        })
        .addLargeButton("Löschen", "selected_delete", function() {
            actions.close();
            pictureFolder.deleteSelected(selected, countstr);
        })
        .addButton("Abbrechen", "cancel", cancel)
        .onEscape(cancel)
        .show();
});

// DOWNLOAD Folder
$("body").on("click", "#admin_pictures_download", function() {
    var w = window.open("api/pictures/zip?id="+pictureFolder.id, "_blank");
    w.focus();
});

// PICTURE Click
$("body").on("click", ".picture_picture", function(e) {
    var target = $(e.target);
    if (target.is("div.picture_draggable") || target.parents("div.picture_draggable").length) {
        return;
    }

    if (pictureFolder.selectMultiples) {
        $(this).toggleClass("picture_selected");
    } else {
        var medium = $(this).attr("m");
        var name = $(this).attr("title");
        var id = $(this).attr("picture");
        var dlg = new Dialog();
        var close = function() {dlg.close()};
        var submit = function() {
            dlg.block();
            var dlgid = dlg.getId();
            var newname = $("#dialog_"+dlgid+"_input_picture_name_"+id).val();
            if (newname == "") {
                var notify = new Notification();
                notify.info("Der Name darf nicht leer sein");
                dlg.unblock();
            } else {
                pictureFolder.renamePicture(id, newname, function(result) {
                    dlg.unblock();
                    if (!result) {
                        var notify = new Notification();
                        notify.error("Änderungen konnten nicht gespeichert werden");
                    } else {
                        var notify = new Notification();
                        notify.success("Änderungen wurden gespeichert");
                        var thumbnail = $("#picture_"+id).attr("t");
                        dlg.close();
                        $("#picture_name_"+id).text(newname);
                    }
                });
            }
        };     
		var edit = function() {
			dlg.close();
			dlg = new Dialog().editPicture(id);
		}
        
        var download = function() {
            window.open("api/pictures/download?id="+id, '_blank');
        }
        
        var deletePic = function() {
            dlg.hide();
            var deleteConfirm = new Dialog();
            deleteConfirm.confirm(
                "Wirklich löschen?", 
                `Wollen Sie das Bild "${name}" wirklich löschen?`,
                "Ja",
                "Nein",
                function(result) {
                    if (result) {
                        deleteConfirm.block();
                        pictureFolder.deletePicture(id, function(result) {
                            deleteConfirm.unblock();
                            if (result) {
                                var notify = new Notification();
                                notify.success("Bild wurde gelöscht");
                                deleteConfirm.close();
                                dlg.unblock();
                                dlg.close();
                                pictureFolder.animateOut("#picture_"+id);
                            } else {
                                var notify = new Notification();
                                notify.error("Bild konnte nicht gelöscht werden");
                            }
                        });
                    } else {
                        deleteConfirm.close();
                        dlg.reshow();
                    }
                }
            );
        };
        dlg.build()
            .addTitle("Bild verwalten")
            .addHtml(`<div style="text-align: center"><img align="center" class="dialog_picture" src="${medium}" /></div><br />`)
            .addButton("Speichern", "save", submit)
            .addButton("Bearbeiten", "edit", edit)
            .addButton("Download", "download", download)
            .addButton("Abbrechen", "cancel", close)
            .addDeleteButton("Löschen", "delete", deletePic)
            .onEscape(close)
            .onEnter(submit)
            .addInput("Name", name, "Name des Bildes", "picture_name_" + id, submit)
            .addHtml(`Bild-Pfad: <br><span class="grey-text text-darken-2">${medium}</span>`)
            .show()
            .focus("picture_name_"+id);
    }
});

// FOLDER BAR Click
$("body").on("click", "#picture_folder_path .folder_path_part", function(e) {
    var id = $(this).attr("folder");
    pictureFolder.navigate(id);
});

// FOLDER Click
$("body").on("click", ".picture_folder", function(e) {
    var target = $(e.target);
    if (target.is("div.picture_draggable") || target.parents("div.picture_draggable").length) {
        return;
    }

    if (pictureFolder.selectMultiples) {
        if ($(this).attr("type") == "folder") {
            $(this).toggleClass("picture_selected");
        } else {
            var notify = new Notification();
            notify.info(`Sie befinden sich im Auswahlmodus.<br />
                         Das übergeordnete Verzeichnis kann nicht ausgewählt werden.`);
        }
    } else {
        var id = $(this).attr("folder");
        if (target.is("div.picture_folder_name") && $(this).attr("type") == "folder") {
            var dlg = new Dialog();
            dlg.input(
                "Ordner umbenennen", 
                "Benennen Sie den Ordner um",
                "Namen eingeben",
                $(this).attr("title"),
                "Ordnername",
                "Ok",
                "Abbrechen",
                function(newname) {
                    if (!newname) {
                        dlg.close();
                    } else {
                        if (newname == "") {
                            var notify = new Notification();
                            notify.info("Der Name darf nicht leer sein");
                        } else {
                            dlg.block();
                            pictureFolder.renameFolder(id, newname, function(result) {
                                dlg.unblock();
                                var notify = new Notification();
                                if (!result) {
                                    notify.error("Ordner konnte nicht umbenannt werden");
                                } else {
                                    notify.success("Ordner umbenannt");
                                    $("#folder_name_"+id).text(newname);
                                    $("#folder_"+id).attr("title", newname);
                                    dlg.close();
                                }
                            });
                        }
                    }
                }
            );
        } else {
            pictureFolder.navigate($(this).attr("folder"));                    
        }
    }
});

// Picture Contextmenu
$("body").on("contextmenu", ".picture_picture", function(e) {
    e.preventDefault();
    $(this).toggleClass("picture_selected");
});

// Folder Contextmenu
$("body").on("contextmenu", ".picture_folder", function(e) {
    e.preventDefault();
    if ($(this).attr("type") == "folder") {
        $(this).toggleClass("picture_selected");
    }
});

$("body").on("click", ".pictures_help", function() {
    var dlg = new Dialog();

    var close = function() {
        dlg.close();
    };

    dlg.build()
        .addTitle("Bilder: Hilfe")
        .addHtml(`
            <h4>Bilder hochladen</h4>
            Verwenden Sie den Button "Bilder hochladen", um neue Bilder in den aktuellen
            Ordner zu laden. Sie können mehrere Bilder gleichzeitig hochladen.

            <h4>Bild verwalten</h4>
            Klicken Sie auf ein Bild, um es umzubenennen oder zu löschen.

            <h4>Ordner erstellen</h4>
            Verwenden Sie den Button "Ordner erstellen", um einen neuen Ordner zu erstellen.
            Das Sortieren / Verschieben von Bildern und Ordnern hat keinen Einfluss auf die
            Galeriezuweisung.

            <h4>Ordner umbenennen</h4>
            Um einen Ordner umzubenennen, klicken Sie auf den Ordnernamen.

            <h4>Verschieben von Elementen</h4>
            Ziehen Sie ein Bild oder einen Ordner auf einen Ordner, um es zu verschieben.
            Haben Sie mehrere Elemente markiert, werden alle markierten Elemente verschoben.

            <h4>Markieren von Elementen</h4>
            Verwenden Sie den Rechtsklick (langes Drücken auf mobilen Geräten), um ein Element 
            zu markieren. Alternativ können Sie in den Auswahlmodus wechseln, um Elemente per 
            Linksklick zu markieren.

            <h4>Aktionen für markierte Elemente</h4>
            Haben Sie ein oder mehrere Elemente ausgewählt, können Sie diese über den Button "Aktionen"
            löschen oder einer Galerie hinzufügen.<br />
            Zum Löschen markierter Elemente können Sie auch die Entfernen Taste drücken.
        `)
        .addButton("Ok", "ok", close)
        .onEscape(close)
        .onEnter(close)
        .show();
});